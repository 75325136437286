<template>
  <v-container
    fluid
    class="section__item background-position"
    :class="{ backgroundLeft: model.focusLeft }"
    :style="{ backgroundImage: 'url(' + model.img + ')' }"
  >
    <v-row
      v-if="
        !model.slider &&
          !model.TextColumn &&
          !model.TextColumns &&
          !model.WhatWeDo &&
          !model.Testimonials &&
          !model.Profiles
      "
    >
      <v-col
        :class="['section__column', model.justifyContentRight ? 'goRight' : '']"
      >
        <h4 class="section__name" :class="{ txtWhite: model.white }">
          {{ model.Name }}
        </h4>
        <div
          class="section__content"
          :class="{
            disclaimer: model.Disclaimer,
            small__banner: model.BannerSmall,
          }"
        >
          <div
            class="section__content__wrapper"
            :class="{
              small__banner: model.BannerSmall,
              focusLeft: model.focusLeft,
            }"
          >
            <h2 class="section__title" :class="{ txtWhite: model.white }">
              {{ model.Title }}
            </h2>
            <h3 class="section__subtitle">{{ model.Subtitle }}</h3>
            <p class="section__description" :class="{ txtWhite: model.white }">
              {{ model.Description }}
            </p>
            <div
              class="section__description"
              :class="{ txtWhite: model.white }"
              v-html="model.HTMLDescription"
            ></div>
            <div
              class="text-caption"
              :class="{ txtWhite: model.white }"
              v-html="model.HTMLCaption"
            ></div>
            <a
              v-if="model.ctaButton"
              class="section__btn"
              :class="{ btnWhite: model.white }"
              :href="model.ctaLink"
              >{{ model.ctaButton }}</a
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="model.Profiles">
      <v-col class="section__column mt-12">
        <h4 class="section__name">{{ model.Name }}</h4>
        <div class="section__content section__content--profiles">
          <div class="" v-for="(bio, i) in model.Bios" :key="i">
            <v-row v-if="bio.left">
              <v-col cols="12" md="6" class="bio__column">
                <img :class="{ bio__image: bio.last }" :src="bio.img" />
              </v-col>
              <v-col cols="12" md="6" align-self="center" class="bio__column">
                <h2 class="bio__title">{{ bio.Title }}</h2>
                <h3 class="bio__subtitle">
                  <strong>{{ bio.Subtitle }}</strong>
                </h3>
                <p>
                  <a class="bio__linkedin" :href="'https://www.' + bio.Linkedin">{{
                    bio.Linkedin
                  }}</a>
                </p>
                <p>
                  <a class="bio__email" :href="'mailto:' + bio.Email">{{
                    bio.Email
                  }}</a>
                </p>
                <p>
                  <a class="bio__tel" :href="'tel:' + bio.Tel"
                    >Tel: {{ bio.Tel }}</a
                  >
                </p>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="6" align-self="center" class="bio__column">
                <h2 class="bio__title">{{ bio.Title }}</h2>
                <h3 class="bio__subtitle">
                  <strong>{{ bio.Subtitle }}</strong>
                </h3>
                <p>
                  <a class="bio__linkedin" :href="'https://www.' + bio.Linkedin">{{
                    bio.Linkedin
                  }}</a>
                </p>
                <p>
                  <a class="bio__email" :href="'mailto:' + bio.Email">{{
                    bio.Email
                  }}</a>
                </p>
                <p>
                  <a class="bio__tel" :href="'tel:' + bio.Tel"
                    >Tel: {{ bio.Tel }}</a
                  >
                </p>
              </v-col>
              <v-col cols="12" md="6" class="bio__column">
                <img
                  :class="{ bio__last: bio.last }"
                  class="bio__image"
                  :src="bio.img"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="model.TextColumn" class="section__column mt-12">
      <v-col cols="12" md="6" v-if="model.TextColumn2">
        <div v-html="model.TextColumn1" class="section__column__one"></div>
      </v-col>
      <v-col cols="12" v-else>
        <div v-html="model.TextColumn1" class="section__column__one"></div>
      </v-col>
      <v-col cols="12" md="6" v-if="model.TextColumn2">
        <img class="slide__decoration" :src="model.Decoration" />
        <div v-html="model.TextColumn2" class="section__column__two"></div>
      </v-col>
    </v-row>
    <v-row v-if="model.TextColumns">
      <v-col cols="12">
        <v-row class="section__column">
          <v-col cols="12" md="6">
            <div v-html="model.TextColumns1"></div>
          </v-col>
          <v-col cols="12" md="6">
            <div v-html="model.TextColumns2"></div>
          </v-col>
        </v-row>
        <v-row class="section__column">
          <v-col cols="12">
            <div v-html="model.TextColumns3"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="model.Testimonials" class="section__column">
      <v-row>
        <v-col cols="12">
          <h3 class="text-column__title">{{ model.Title }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="(testi, i) in model.Testimonial"
          :key="i"
        >
          <img class="slide__decoration" :src="model.Decoration" />
          <blockquote
            class="testimonial testimonial__content"
            v-html="testi.Content"
          ></blockquote>
          <blockquote
            class="testimonial testimonial__author"
            v-html="testi.Author"
          ></blockquote>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-if="model.WhatWeDo" class="section__column">
      <v-row>
        <v-col cols="12">
          <h3 class="text-column__title">{{ model.Title }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h4 class="section__name">{{ model.Name }}</h4>
        </v-col>
        <v-col cols="12" md="6" v-for="(block, i) in model.What" :key="i">
          <a class="block__link" :href="block.link">
            <div class="block__wrapper">
              <img class="block__image" :src="block.img" />
              <h2 class="block__title" v-html="block.title"></h2>
              <h3 class="block__subtitle" v-html="block.subtitle"></h3>
            </div>
          </a>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-if="model.slider">
      <v-col class="section__column">
        <h4 class="section__name" :class="{ txtWhite: model.white }">
          {{ model.Name }}
        </h4>
        <div class="section__content">
          <div class="section__content__wrapper--slide">
            <h2
              class="section__title section__title--slider"
              :class="{ txtWhite: model.white }"
            >
              {{ model.Title }}
            </h2>
            <h3 class="section__subtitle">{{ model.Subtitle }}</h3>
            <img class="slide__decoration" :src="model.Decoration" />
            <v-carousel
              cycle
              interval="6000"
              hide-delimiter-background
              :show-arrows="false"
            >
              <v-carousel-item
                class="slide__wrapper"
                v-for="(text, i) in model.SlideSection"
                :key="i"
              >
                <v-sheet class="slide__item">
                  <v-row align="center" justify="center">
                    <v-col class="slide__column">
                      <div class="display-1 text-center slide__content">
                        <div class="slide__content__wrapper">
                          <p
                            class="slide__description"
                            :class="{ txtWhite: model.white }"
                            v-html="text"
                          ></p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";
.section {
  &__item {
    background-size: cover;
    height: 100%;
    padding-bottom: 0;
    &.background-position {
      background-position: center right;
    }
    &.backgroundLeft {
      background-position: center left;
    }
  }
  &__title {
    margin-bottom: 9px;
    font-family: $lato;
    color: $primary;
    font-size: 80px;
    line-height: 120%;
    text-transform: uppercase;
    &--slider.txtWhite {
      color: $white !important;
    }
  }
  &__subtitle {
    font-family: $garamond;
    margin-bottom: 9px;
    font-size: 40px;
    line-height: 120%;
    font-weight: 600;
    font-style: italic;
  }
  &__description {
    display: block;
    font-family: $lato;
    font-size: 18px;
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 10px;
  }
  &__name {
    margin-top: 0px;
    margin-bottom: 50px;
    font-family: $garamond;
    color: $dark-background;
    font-size: 33px;
    line-height: 120%;
    padding-bottom: 4px;
    border-bottom: 2px solid $primary;
    font-weight: 700;
    display: inline-block;
    height: auto;
  }
  &__column {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    padding-bottom: 0;
    &__one {
      font-family: $lato;
      font-size: 18px;
      line-height: 150%;
    }
    &__two {
      margin: 10px 0;
      padding: 10px 0px;
      border: 1px none #000;
      font-family: $garamond;
      color: $dark-background;
      font-size: 25px;
      line-height: 130%;
      font-style: italic;
      font-weight: 500;
    }
  }
  &__content {
    width: 100%;
    padding: 10px 10px 0 10px;
    border: 1px none #000;
    background-color: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    &__wrapper {
      text-align: left;
      width: 50%;
      padding: 0px 10px 10px;
      border: 1px none #000;
      background-color: transparent;
      &--slide {
        width: 60%;
      }
      &.small__banner {
        text-align: center;
        width: auto;
        background-color: transparent;
        align-self: center;
      }
    }
    &--profiles {
      min-height: 100%;
    }
  }
  &__btn {
    margin-top: 2%;
    display: inline-block;
    padding: 16px 25px;
    border: 1px solid $primary;
    background-color: $btn-background;
    font-family: $lato;
    color: $primary;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    &:hover {
      background-color: $primary;
      color: $btn-hover;
    }
  }
}

.slide {
  &__description {
    margin-bottom: 10px;
    padding: 10px 0px;
    border: 1px none #000;
    font-family: $garamond;
    color: $dark-background;
    font-size: 25px;
    line-height: 130%;
    font-style: italic;
    font-weight: 500;
  }

  &__decoration {
    display: block;
    margin: 0 auto;
  }
}

.bio {
  &__title {
    margin-top: auto;
    margin-bottom: 1%;
    font-family: $lato;
    color: $primary;
    font-size: 45px;
    line-height: 120%;
    font-weight: 900;
    text-transform: uppercase;
  }
  &__subtitle {
    margin-top: 0px;
    margin-bottom: 2%;
    font-family: $garamond;
    color: $dark-background;
    font-weight: bold;
    font-size: 33px;
    line-height: 120%;
    font-weight: 500;
  }
  &__linkedin {
    font-family: $lato;
    font-size: 18px;
    line-height: 140%;
    color: $primary;
    text-decoration: none;
    display: block;
  }
  &__email {
    font-family: $lato;
    font-size: 18px;
    line-height: 140%;
    color: $primary;
    text-decoration: none;
    display: block;
  }
  &__tel {
    font-family: $lato;
    font-size: 18px;
    line-height: 140%;
    color: $dark-background;
    text-decoration: none;
  }
  &__column {
    padding-bottom: 0;
  }
  &__image {
    max-width: 100%;
  }
  &__last {
    margin-bottom: -14px;
  }
}

.goRight {
  margin-right: 0px !important;
}

.testimonial {
  padding: 10px 0px;
  border: 1px none #000;
  font-family: $garamond;
  color: $dark-background;
  font-size: 25px;
  line-height: 130%;
  font-style: italic;
  font-weight: 500;
  &__content {
    margin-top: 15px;
  }
  &__author {
    margin-bottom: 40px;
  }
}

.block {
  &__link {
    text-decoration: none;
    &:hover > .block__wrapper {
      border: 1px solid $primary;
    }
  }
  &__image {
    max-width: 100%;
  }
  &__title {
    margin-top: auto;
    margin-bottom: 1%;
    font-family: $lato;
    color: $primary;
    font-size: 51px;
    line-height: 120%;
    text-transform: uppercase;
    text-decoration: none;
  }
  &__subtitle {
    margin-top: 0px;
    margin-bottom: 2%;
    font-family: $garamond;
    color: $dark-background;
    font-size: 33px;
    line-height: 120%;
    font-weight: 500;
    font-style: italic;
  }
}

.txtWhite {
  color: $white;
}

.btnWhite {
  background-color: rgba(0, 0, 0, 0.51);
}

.disclaimer {
  min-height: 100%;
  padding-bottom: 40px;
}

.slide__content,
.theme--dark.v-sheet {
  background-color: transparent !important;
  border-color: transparent !important;
}

.small__banner {
  min-height: 100%;
  padding-bottom: 70px;
}

@media (max-width: 2000px) {
  .section {
    &__content {
      &__wrapper {
        width: 70%;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 30px;
        align-self: flex-start;
        &.focusLeft {
          align-self: flex-end;
        }
      }
    }
    &__description {
      color: $dark-background;
    }
    &__title {
      color: $dark-background;
    }
  }
}
@media (max-width: 767px) {
  .slide {
    &__content {
      &__wrapper {
        width: 100%;
      }
    }
    &__description {
      font-size: 20px;
    }
  }
  .section {
    &__title {
      font-size: 35px;
      &--slider {
        font-size: 30px;
        text-align: center;
      }
    }
    &__subtitle {
      font-size: 25px;
    }
    &__content {
      &__wrapper {
        width: 100%;
        &--slide {
          width: 100%;
        }
      }
    }
  }
}
</style>
